<script>
import NavMixin from "@/plugins/mixins/Nav";

export default {
    name: "TabsNav",

    mixins: [NavMixin],

    data() {
        return {
            tabs: []
        }
    },

    props: {
        items: {
            type: Array,
            require: false,
            default: null
        },
    },

    mounted() {
        if(this.items) {
            this.tabs = this.items
        } else {
            if(this.breadcrumbs() && this.breadcrumbs()[0]) {
                this.tabs = this.breadcrumbs()[0]['items']
            }
        }
    }
}
</script>

<template>
    <div class="tabs-nav">
        <div class="tabs-nav__inner">
            <template v-for="(tab, key) in tabs">
                <div class="tabs-nav__item" :class="{ 'active': tab.active }" :key="key" v-if="can(tab.permissions)">
                    <router-link class="tabs-nav__item-link" :to="tab.route">
                        <v-icon class="tabs-nav__item-icon" v-if="tab.icon">{{ tab.icon }}</v-icon>
                        <span class="tabs-nav__item-text">{{ $t(tab.title) }}</span>
                    </router-link>
                </div>
            </template>
        </div>
    </div>
</template>
